import React, { useMemo } from 'react';
import { Badge, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTable from '../../../components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from '../../../components/common/advance-table/AdvanceTableFooter';
import Loader from '../../../components/Loader';
import Message from '../../../components/Message';
import useAdvanceTable from '../../../hooks/useAdvanceTable';
import AdvanceTableProvider from '../../../providers/AdvanceTableProvider';

const ApprovingOrderPendings = ({ data, isLoading, isError }) => {
	const columns = useMemo(
		() => [
			{
				accessorKey: 'title',
				header: '审批事项',
				meta: {
					headerProps: { className: 'sticky-column text-900' },
					cellProps: {
						className: 'fw-semibold',
					},
				},
				cell: ({ row: { original } }) => {
					const { approval } = original;
					return (
						<Link to={`/approval/order/${approval.detail.orderOID}/detail/${approval._id}`} className='text-danger'>
							{approval.title}
						</Link>
					);
				},
			},
			{
				accessorKey: 'customerName',
				header: '客户名称',
				meta: {
					headerProps: { className: 'pe-3 text-900 text-start' },
					cellProps: { className: 'text-start' },
				},
				cell: ({ row: { original } }) => {
					const { customerInfo } = original;
					return <span className='text-danger'>{customerInfo.customerName}</span>;
				},
			},
			{
				accessorKey: 'approvalStatus',
				header: '审批状态',
				meta: {
					headerProps: { className: 'pe-3 text-900' },
				},
				cell: () => {
					return (
						<Badge bg='danger' className='me-2'>
							<span className='fs-11'>待审批</span>
						</Badge>
					);
				},
			},

			{
				accessorKey: 'applicantName',
				header: '申请人',
				cell: ({ row: { original } }) => {
					const { createdUser } = original;
					return <span className='fs-11'>{createdUser.name}</span>;
				},
			},
			{
				accessorKey: 'assistantName',
				header: '销售助理',
				cell: ({ row: { original } }) => {
					const { assistantUser } = original;
					return <span className='fs-11'>{assistantUser.name}</span>;
				},
			},
		],
		[]
	);

	const table = useAdvanceTable({
		data,
		columns: columns,
		sortable: true,
		pagination: true,
		perPage: 7,
		initialState: {
			sorting: [{ id: 'createdAt', desc: true }],
		},
	});

	return (
		<AdvanceTableProvider {...table}>
			<AdvanceTable
				headerClassName='bg-200 text-nowrap align-middle font-sans-serif'
				rowClassName='btn-reveal-trigger text-nowrap align-middle'
				tableProps={{
					className: 'fs-10 fw-semibold mb-0 overflow-hidden',
				}}
			/>
			{!isLoading && data.length === 0 && (
				<div className='my-5 w-100 text-center'>
					<p className=''>暂无需要审批的订单</p>
				</div>
			)}
			{isLoading && <Loader />}
			{isError && <Message animation='border' variant='primary' className='mt-3' />}
			<Card.Footer>
				<AdvanceTableFooter rowInfo navButtons />
			</Card.Footer>
		</AdvanceTableProvider>
	);
};

export default ApprovingOrderPendings;
