/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Badge, Button, Card, Col, ListGroup, Row, Spinner } from 'react-bootstrap';
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SubtleBadge from '../../components/common/SubtleBadge';
import Loader from '../../components/Loader';
import { useGetApprovalByIdQuery, useUpdateOrderApprovalByIdMutation } from '../../slices/approvalsApiSlice';
import { useNotifyOrderApprovedToApplicantMutation } from '../../slices/notificationsApiSlice';
import { useApproveOrderMutation, useGetOrderDetailsQuery } from '../../slices/ordersApiSlice';

const OrderApprovalDetailScreen = () => {
	const { orderId, approvalId } = useParams();
	const { userInfo } = useSelector((state) => state.auth);
	const navigate = useNavigate();

	const { data: approval, isLoading: loadingApproval, error: loadingApprovalError, refetch } = useGetApprovalByIdQuery(approvalId);

	const { data: order, isLoading: loadingOrder, error: loadingOrderError } = useGetOrderDetailsQuery(orderId);

	const [updateOrderApprovalStatus, { isLoading: updatingApproval, error: updateError }] = useUpdateOrderApprovalByIdMutation();

	const [approveOrder, { isLoading: approvingOrder, error: approveError }] = useApproveOrderMutation();

	const [notifyOrderApprovedToApplicant] = useNotifyOrderApprovedToApplicantMutation();

	const handlePass = async () => {
		try {
			const approved_res = await updateOrderApprovalStatus({
				_id: approvalId,
				status: 'approved',
			}).unwrap();

			const { approver, applicant, assistant, detail, _id, status } = approved_res;

			const approve_order_res = await approveOrder({
				orderId: detail.orderOID,
				approvalResult: status,
			}).unwrap();

			// notify applicant
			await notifyOrderApprovedToApplicant({
				toUser: applicant,
				fromUser: approver,
				content: { message_text: `订单#${approve_order_res.orderId}审批通过 ✅`, detail, approvalId: _id },
			});

			// notify assistant
			await notifyOrderApprovedToApplicant({
				toUser: assistant,
				fromUser: approver,
				content: { message_text: `订单#${approve_order_res.orderId}审批通过 ✅`, detail, approvalId: _id },
			});

			toast.success('审批通过');
			navigate(-1);
		} catch (error) {
			toast.error(error?.data?.message || error.error);
		}
	};

	const handleUnagree = async () => {
		try {
			const approved_res = await updateOrderApprovalStatus({
				_id: approvalId,
				status: 'reject',
			}).unwrap();

			const { approver, applicant, assistant, detail, _id, status } = approved_res;

			const approve_order_res = await approveOrder({
				orderId: detail.orderOID,
				approvalResult: status,
			}).unwrap();

			await notifyOrderApprovedToApplicant({
				toUser: applicant,
				fromUser: approver,
				content: { message_text: `订单#${approve_order_res.orderId}被驳回 🚫`, detail, approvalId: _id },
			});

			await notifyOrderApprovedToApplicant({
				toUser: assistant,
				fromUser: approver,
				content: { message_text: `订单#${approve_order_res.orderId}被驳回 🚫`, detail, approvalId: _id },
			});
			toast.success('申请已驳回');
			navigate(-1);
		} catch (error) {
			toast.error(error?.data?.message || error.error);
		}
	};

	const handleRenew = async () => {
		// 再次提交
		await updateOrderApprovalStatus({
			_id: approvalId,
			status: 'reject',
		});

		toast.success('重新提交成功');
		navigate('/approval/list');
	};
	useEffect(() => {
		refetch();
	}, [refetch]);
	return (
		<div>
			{loadingApproval ? (
				<Loader />
			) : loadingOrder ? (
				<Loader />
			) : (
				<>
					<Row className='g-3'>
						<Col xs={12}>
							<Card className='mb-1'>
								<Card.Body>
									<Row className='flex-between-center'>
										<Col md>
											<h5 className='my-2 mb-md-0 me-1'>
												审批: 订单编号 # {order.orderId}
												{userInfo.role === 'manager' && order.isApproved && approval.status === 'approved' && (
													<>
														<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='success'>
															<FontAwesomeIcon icon='check' className='me-1' />
															审批通过
														</Badge>
													</>
												)}
												{userInfo.role === 'manager' && order.isApproved && approval.status === 'reject' && (
													<>
														<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='danger'>
															<FontAwesomeIcon icon='xmark' className='me-1' />
															被驳回
														</Badge>
													</>
												)}
											</h5>
										</Col>
										<Col xs='auto'>
											{userInfo.role === 'manager' && approval.status === 'waitingApproval' && (
												<>
													{updatingApproval || approvingOrder ? (
														<Spinner variant='primary' className='me-3 align-middle' />
													) : (
														<>
															<Button variant='success' onClick={handlePass}>
																<FontAwesomeIcon icon='check' className='me-2' />
																同意
															</Button>
															<Button className='mx-3' variant='outline-danger' onClick={handleUnagree}>
																<FontAwesomeIcon icon='xmark' className='me-2' />
																不同意
															</Button>
														</>
													)}
												</>
											)}
											{/* {approval.status === 'reject' && (
												<>
													<Button variant='outline-success' onClick={handleRenew} className='me-2'>
														<FontAwesomeIcon icon='rotate' className='me-2' />
														再次提交
													</Button>
												</>
											)} */}
											{/* {userInfo.role === 'manager' && approval.status === 'reject' && (
												<>
													<Button variant='outline-success' onClick={handleRenew} className='me-2'>
														<FontAwesomeIcon icon='rotate' className='me-2' />
														再次提交
													</Button>
												</>
											)} */}

											<Button
												variant='outline-primary'
												className='me-3'
												onClick={() => {
													navigate(-1);
												}}
											>
												<FontAwesomeIcon icon='arrow-left' className='me-2' />
												返回
											</Button>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
						<Col md={12}>
							<Card>
								<Card.Body>
									<Row>
										<Col>
											<ListGroup variant='flush'>
												<ListGroup.Item key={order.orderId}>
													<Row>
														<Col>
															<p>
																<strong>客户名称: </strong>
																{order.customerInfo.customerName}
															</p>
															{order.customerInfo.staffName && (
																<p>
																	<strong>联系人: </strong>
																	{order.customerInfo.staffName}
																</p>
															)}
															{order.customerInfo.staffPhone && (
																<p>
																	<strong>电话: </strong>
																	{order.customerInfo.staffPhone}
																</p>
															)}
															{order.customerInfo.staffWechat && (
																<p>
																	<strong>微信: </strong>
																	{order.customerInfo.staffWechat}
																</p>
															)}
															{order.customerInfo.staffEmail && (
																<p>
																	<strong>邮箱: </strong>
																	<a href={`mailto:${order.customerInfo.staffEmail}`}>{order.customerInfo.staffEmail}</a>
																</p>
															)}
															<p>
																<strong>地址: </strong>
																{order.customerInfo.customerAddress}
															</p>
														</Col>
														<Col>
															<ListGroup variant='flush'>
																<ListGroup.Item>
																	<p>
																		<strong>业务联系人: </strong>
																		{order.saleUser ? order.saleUser.name : order.createdUser.name}
																	</p>
																	<p>
																		<strong>电话: </strong>
																		{order.saleUser ? order.saleUser.phone : order.createdUser.phone}
																	</p>
																	<p>
																		<strong>邮箱: </strong>
																		<a href={`mailto:${order.saleUser ? order.saleUser.email : order.createdUser.email}`}>
																			{order.saleUser ? order.saleUser.email : order.createdUser.email}
																		</a>
																	</p>
																</ListGroup.Item>
																<ListGroup.Item>
																	<p>
																		<strong>销售助理: </strong>
																		{order.assistantUser ? order.assistantUser.name : order.createdUser.name}
																	</p>
																	<p>
																		<strong>电话: </strong>
																		{order.assistantUser ? order.assistantUser.phone : order.createdUser.phone}
																	</p>
																	<p>
																		<strong>邮箱: </strong>
																		<a href={`mailto:${order.assistantUser ? order.assistantUser.email : order.createdUser.email}`}>
																			{order.assistantUser ? order.assistantUser.email : order.createdUser.email}
																		</a>
																	</p>
																</ListGroup.Item>
															</ListGroup>
														</Col>
													</Row>
												</ListGroup.Item>
												<ListGroup.Item>
													<h5 className='my-3'>
														<strong>产品列表</strong>
													</h5>
													<ListGroup.Item className='bg-warning-subtle'>
														<Row className='text-warning'>
															<Col>
																<strong>货号</strong>
															</Col>
															<Col>
																<strong>产品名称</strong>
															</Col>
															<Col>
																<strong>数量</strong>
															</Col>
															<Col>
																<strong>进价</strong>
															</Col>
															<Col>
																<strong>单价</strong>
															</Col>
															<Col>
																<strong>总价</strong>
															</Col>
														</Row>
													</ListGroup.Item>
													{order.orderItems.map((item, index) => (
														<>
															<ListGroup.Item key={index}>
																<Row>
																	<Col>
																		<Link className='text-800' to=''>
																			{item.productCode}
																			{item.sellingPrice < item.minimumSellingPrice && <FontAwesomeIcon icon='star' className='ms-2 mb-1 text-danger fs-11' />}
																		</Link>
																	</Col>
																	<Col>
																		<Link className='text-800'>{item.name}</Link>
																	</Col>
																	<Col>{item.qty}</Col>
																	<Col>¥{item.purchasingPrice}</Col>
																	<Col>
																		{item.sellingPrice < item.minimumSellingPrice ? (
																			<>
																				<del className='me-2 fw-bolder'>¥{item.minimumSellingPrice.toFixed(2)}</del>
																				<span className='me-2 fw-bolder text-danger'>¥{item.sellingPrice.toFixed(2)}</span>
																				<p>
																					<SubtleBadge bg='danger'>降价{Number(item.sellingPrice - item.minimumSellingPrice).toFixed(2)}元</SubtleBadge>
																				</p>
																			</>
																		) : (
																			<span className='me-2 fw-bolder text-800'>¥{item.sellingPrice.toFixed(2)}</span>
																		)}
																	</Col>
																	<Col>
																		{item.sellingPrice < item.minimumSellingPrice ? (
																			<>
																				<del className='me-2'>¥{(item.qty * item.minimumSellingPrice).toFixed(2)}</del>
																				<span className='fw-bolder text-danger'>¥{(item.qty * item.sellingPrice).toFixed(2)}</span>
																				<p>
																					<SubtleBadge bg='danger'>共计优惠{Number(item.minimumSellingPrice * item.qty - item.sellingPrice * item.qty).toFixed(2)}元</SubtleBadge>
																				</p>
																			</>
																		) : (
																			<span>¥{(item.qty * item.minimumSellingPrice).toFixed(2)}</span>
																		)}
																	</Col>
																</Row>
															</ListGroup.Item>
														</>
													))}
												</ListGroup.Item>
												<ListGroup.Item>
													<h5 className='my-3'>
														<strong>货款支付</strong>
													</h5>
													<p>
														<strong>方式: </strong>
														{order.paymentMethod}
													</p>

													<Row className='mb-3'>
														<Col>产品总价</Col>
														<Col>
															<CountUp end={order.itemsPrice.toFixed(2)} prefix='¥' separator=',' duration={1} decimals={2} />
														</Col>
													</Row>

													{order.paymentMethod === '部分支付' && order.prePaymentOption === '百分比' && (
														<>
															<Row className='mb-3'>
																<Col>预付百分比</Col>
																<Col>
																	<span className='text-primary fs-8'>
																		<strong>{order.prePaymentPercent} %</strong>
																	</span>
																</Col>
															</Row>
															<Row className='mb-3'>
																<Col>剩余尾款</Col>
																<Col>
																	<strong>¥ {order.percentBalance}</strong>
																</Col>
															</Row>
															<Row className='mb-3'>
																<Col md={7}>
																	<ul className='timeline-order'>
																		{order.scheduleNodes.length !== 0 &&
																			order.scheduleNodes.map((node) => (
																				<li className='timeline-item mb-3'>
																					<Card>
																						<Card.Header className='bg-primary-subtle pb-1'>
																							<Card.Title>
																								<h6 className='text-1100'>{node.title}</h6>
																							</Card.Title>
																						</Card.Header>
																						<Card.Body className='pt-3 pb-3'>
																							<Row>
																								<Col sm={6}>账期</Col>
																								<Col sm={6}>{node.paymentDays}天</Col>
																								<Col sm={6}>付款百分比</Col>
																								<Col sm={6}>{node.paymentPercent}%</Col>
																								<Col sm={6}>付款金额</Col>
																								<Col sm={6}>{node.paymentAmount} 元</Col>
																							</Row>
																						</Card.Body>
																					</Card>
																				</li>
																			))}
																	</ul>
																</Col>
															</Row>
														</>
													)}
													{order.paymentMethod === '部分支付' && order.prePaymentOption === '预付款' && (
														<>
															<Row className='mb-2'>
																<Col>预支付</Col>
																<Col>
																	<strong>¥ {order.prePayment}</strong>
																</Col>
															</Row>
															<Row className='mb-2'>
																<Col>剩余尾款</Col>
																<Col>
																	<strong>¥ {order.prePaymentBalance}</strong>
																</Col>
															</Row>
														</>
													)}
													<Row className='mb-3'>
														<Col>
															<strong>总价</strong>
														</Col>
														<Col>
															<CountUp end={order.totalPrice.toFixed(2)} prefix='¥' separator=',' duration={1} decimals={2} />
														</Col>
													</Row>
												</ListGroup.Item>
											</ListGroup>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</>
			)}
		</div>
	);
};

export default OrderApprovalDetailScreen;
