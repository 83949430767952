import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo_primary from '../../assets/genechampLogo-word-primary-4x.png';
import logo_white from '../../assets/genechampLogo-word-white-4x.png';
import { useSelector } from 'react-redux';

const Logo = ({ at, width, className, textClass, ...rest }) => {
	const { isDark } = useSelector((state) => state.config);
	return (
		<Link to='/dashboard' className={classNames('text-decoration-none ms-3')} {...rest}>
			<div
				className={classNames(
					'd-flex',
					{
						'align-items-center': at === 'navbar-top',
					},
					className
				)}
			>
				<img src={isDark ? logo_primary : logo_white} alt='Logo' width={width} />
			</div>
		</Link>
	);
};

Logo.propTypes = {
	at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
	width: PropTypes.number,
	className: PropTypes.string,
	textClass: PropTypes.string,
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
