import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import OrderListManagerTable from './OrderListManagerTable';
const OrderListManagerScreen = () => {
	return (
		<>
			<Card>
				<Card.Header>
					<Card.Title className='mb-2'>
						<nav aria-label='breadcrumb'>
							<ol className='breadcrumb'>
								<li className='breadcrumb-item'>
									<a href='/dashboard'>
										<FontAwesomeIcon icon='home' className='me-2' />
										首页
									</a>
								</li>
								<li className='breadcrumb-item active' aria-current='page'>
									订单查询
								</li>
							</ol>
						</nav>
					</Card.Title>
				</Card.Header>
				<Card.Body className='p-0'>
					<OrderListManagerTable />
				</Card.Body>
			</Card>
		</>
	);
};

export default OrderListManagerScreen;
