import { createSlice } from '@reduxjs/toolkit';
import { getItemFromStore } from '../utils/utils';

const initialState = {
	isFluid: getItemFromStore('isFluid', true),
	isRTL: getItemFromStore('isRTL', false),
	isDark: getItemFromStore('isDark', false),
	theme: getItemFromStore('theme', 'light'),
	navbarPosition: getItemFromStore('navbarPosition', 'vertical'),
	disabledNavbarPosition: [],
	isNavbarVerticalCollapsed: getItemFromStore('isNavbarVerticalCollapsed', false),
	navbarStyle: getItemFromStore('navbarStyle', 'transparent'),
	currency: '¥',
	showBurgerMenu: false,
	showSettingPanel: false,
	navbarCollapsed: false,
	openAuthModal: false,
};

const configSlice = createSlice({
	name: 'config',
	initialState,
	reducers: {
		setConfig: (state, action) => {
			const { key, value } = action.payload;
			//console.log('进入reducer', isDark, theme);
			if (key === 'isDark') {
				state.isDark = value;
				localStorage.setItem('isDark', value);
			}
			if (key === 'theme') {
				state.theme = value;
				localStorage.setItem('theme', value);
			}
		},
		refresh: (state, action) => {
			localStorage.clear();
		},
	},
});

export const { setConfig } = configSlice.actions;

export default configSlice.reducer;
