import { APPROVALS_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const approvalsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		initiateQuotaApproval: builder.mutation({
			query: (approval) => ({
				url: `${APPROVALS_URL}/quota`,
				method: 'POST',
				body: { ...approval },
			}),
			invalidatesTags: ['Approval'],
		}),
		initiateCustomerCreateApproval: builder.mutation({
			query: (approval) => ({
				url: `${APPROVALS_URL}/customer`,
				method: 'POST',
				body: { ...approval },
			}),
			invalidatesTags: ['Approval'],
		}),
		initiateOrderApproval: builder.mutation({
			query: (approval) => ({
				url: `${APPROVALS_URL}/order`,
				method: 'POST',
				body: { ...approval },
			}),
			invalidatesTags: ['Approval'],
		}),
		getMyCustomerApprovals: builder.query({
			query: () => ({
				url: `${APPROVALS_URL}/mine/customer`,
			}),
			providesTags: ['Approval'],
			keepUnusedDataFor: 5,
		}),
		getMyOrderApprovals: builder.query({
			query: () => ({
				url: `${APPROVALS_URL}/mine/order`,
			}),
			providesTags: ['Approval'],
			keepUnusedDataFor: 5,
		}),
		getMyOrderApprovalPendings: builder.query({
			query: () => ({
				url: `${APPROVALS_URL}/mine/pendings`,
			}),
			providesTags: ['Approval'],
			keepUnusedDataFor: 5,
		}),
		getMyQuotaApprovals: builder.query({
			query: () => ({
				url: `${APPROVALS_URL}/mine/quota`,
			}),
			providesTags: ['Approval'],
			keepUnusedDataFor: 5,
		}),
		getApprovalById: builder.query({
			query: (approvalId) => ({
				url: `${APPROVALS_URL}/${approvalId}`,
			}),
			providesTags: ['Approval'],
			keepUnusedDataFor: 5,
		}),
		updateCustomerApprovalById: builder.mutation({
			query: (data) => ({
				url: `${APPROVALS_URL}/${data._id}/customer`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['Approval'],
		}),
		updateOrderApprovalById: builder.mutation({
			query: (data) => ({
				url: `${APPROVALS_URL}/${data._id}/order`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['Approval'],
		}),
		deleteApproval: builder.mutation({
			query: (approvalId) => ({
				url: `${APPROVALS_URL}/${approvalId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Approval'],
		}),
	}),
});

export const {
	useInitiateQuotaApprovalMutation,
	useInitiateCustomerCreateApprovalMutation,
	useInitiateOrderApprovalMutation,
	useGetMyCustomerApprovalsQuery,
	useGetMyOrderApprovalsQuery,
	useGetMyQuotaApprovalsQuery,
	useGetApprovalByIdQuery,
	useUpdateCustomerApprovalByIdMutation,
	useUpdateOrderApprovalByIdMutation,
	useDeleteApprovalMutation,
	useGetMyOrderApprovalPendingsQuery,
} = approvalsApiSlice;
