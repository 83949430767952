/* eslint-disable react/react-in-jsx-scope */
import img1 from '../../assets/img/support-desk/reports/1.png';
import img2 from '../../assets/img/support-desk/reports/2.png';
import img3 from '../../assets/img/support-desk/reports/3.png';
import img4 from '../../assets/img/support-desk/reports/4.png';
// import img5 from '../../assets/img/support-desk/reports/5.png';
import img6 from '../../assets/img/support-desk/reports/6.png';
import img7 from '../../assets/img/support-desk/reports/7.png';
import { FiAlertCircle, FiBriefcase, FiClock, FiFileText, FiGitPullRequest, FiHexagon, FiLock, FiRepeat, FiSunrise, FiUser, FiUserCheck, FiUsers } from 'react-icons/fi';

export const DashboardQiuckButton = {
	basicBtn: [
		{
			title: '业务统计',
			description: '报价单列表查询，如需制作报价单请到新建订单页面',
			img: img7,
			link: '/statistics',
		},
		{
			title: '产品信息',
			description: '查看系统中全部产品',
			img: img2,
			link: '/productlist',
		},
	],
	managerBtn: [
		{
			title: '新建订单',
			description: '新建订单 & 制作报价单',
			img: img1,
			link: '/order/create/new',
		},
		{
			title: '订单编辑',
			description: '编辑中的订单 & 审批中的订单',
			img: img4,
			link: '/manager/editlist',
		},
		{
			title: '订单查询',
			description: '跟进订单进度并完成修改状态',
			img: img7,
			link: '/manager/orderlist',
		},
		{
			title: '客户信息',
			description: '查看或添加客户信息',
			img: img6,
			link: '/customerlist',
		},
	],
	financeBtn: [
		{
			title: '订单查询',
			description: '跟进订单进度并完成修改状态',
			img: img7,
			link: '/finance/orderlist',
		},
		{
			title: '客户信息',
			description: '查看或添加客户信息',
			img: img6,
			link: '/customerlist',
		},
	],
	buyerBtn: [
		{
			title: '订单查询',
			description: '跟进订单进度并完成修改状态',
			img: img7,
			link: '/buyer/orderlist',
		},
		{
			title: '客户信息',
			description: '查看或添加客户信息',
			img: img6,
			link: '/customerlist',
		},
	],
	userBtn: [
		{
			title: '新建订单',
			description: '新建订单 & 制作报价单',
			img: img1,
			link: '/order/create/new',
		},
		{
			title: '订单编辑',
			description: '未完成编辑中的订单',
			img: img4,
			link: '/user/editlist',
		},
		{
			title: '订单查询',
			description: '跟进订单进度并完成修改状态',
			img: img7,
			link: '/user/orderlist',
		},
		{
			title: '客户信息',
			description: '管理客户及分配销售员',
			img: img6,
			link: '/customerlist',
		},
	],
	assistantBtn: [
		{
			title: '新建订单',
			description: '帮助销售员新建订单 & 制作报价单',
			img: img1,
			link: '/order/create/new',
		},
		{
			title: '订单编辑',
			description: '未完成编辑中的订单',
			img: img4,
			link: '/assistant/editlist',
		},
		{
			title: '订单查询',
			description: '跟进订单进度并完成修改状态',
			img: img7,
			link: '/assistant/orderlist',
		},
		{
			title: '客户信息',
			description: '查看或添加客户信息',
			img: img6,
			link: '/customerlist',
		},
	],
	saleBtn: [
		{
			title: '新建订单',
			description: '新建订单 & 制作报价单',
			img: img1,
			link: '/order/create/new',
		},
		{
			title: '订单编辑',
			description: '未完成编辑中的订单',
			img: img4,
			link: '/sale/editlist',
		},
		{
			title: '订单查询',
			description: '跟进订单进度并完成修改状态',
			img: img7,
			link: '/sale/orderlist',
		},
		{
			title: '客户信息',
			description: '帮助销售员维护客户信息',
			img: img6,
			link: '/customerlist',
		},
	],
	adminBtn: [
		{
			title: '新建订单-SuperAdmin',
			description: '新建订单 & 制作报价单',
			img: img1,
			link: '/order/create/new',
		},
		{
			title: '订单编辑-SuperAdmin',
			description: '进入订单列表页面',
			img: img4,
			link: '/admin/editlist',
		},
		{
			title: '订单查询-SuperAdmin',
			description: '报价单列表查询，如需制作报价单请到新建订单页面',
			img: img7,
			link: '/admin/orderlist',
		},
		{
			title: '业务统计-SuperAdmin',
			description: '报价单列表查询，如需制作报价单请到新建订单页面',
			img: img7,
			link: '#!',
		},
		{
			title: '客户信息-SuperAdmin',
			description: '查看系统中全部客户',
			img: img3,
			link: '/admin/customerlist',
		},
		{
			title: '产品信息-SuperAdmin',
			description: '查看系统中全部产品',
			img: img2,
			link: '/admin/productlist',
		},
	],
};

export const DashboardAdminQiuckButton = {
	orderBtn: [
		{
			title: '新建订单',
			description: '进入新建订单页面',
			img: img1,
			link: '/sales/order/productList',
		},
		{
			title: '订单编辑',
			description: '进入订单列表页面',
			img: img4,
			link: '/admin/orderlist',
		},
	],
	infoBtn: [
		{
			title: '客户信息',
			description: '您的所有客户信息显示',
			img: img3,
		},
		{
			title: '厂家信息',
			description: '厂家信息维护',
			img: img7,
		},
		{
			title: '用户信息',
			description: '您拥有此权限，可对用户信息进行维护',
			img: img6,
		},
	],
};
export const DashboardSalesQiuckButton = {
	orderBtn: [
		{
			title: '新建订单',
			description: '进入新建订单页面',
			img: img1,
			link: '/sales/order/productList',
		},
		{
			title: '订单查询',
			description: '进入订单页面',
			img: img4,
			link: '/manager/orderlist',
		},
	],
	infoBtn: [
		{
			title: '客户信息',
			description: '您的所有客户信息显示',
			img: img3,
		},
		{
			title: '厂家信息',
			description: '厂家信息维护',
			img: img7,
		},
		{
			title: '用户信息',
			description: '您拥有此权限，可对用户信息进行维护',
			img: img6,
		},
	],
};

export const team = [
	{
		icon: <FiUser className='text-primary fs-7' />,
		color: 'primary',
		title: 'Agents',
		description: "Define agents' scope of work, type, language, and other details.",
	},
	{
		icon: <FiUsers className='text-info fs-7' />,
		color: 'info',
		title: 'Groups',
		description: 'Agents can be organized and unattended tickets can be notified.',
	},
	{
		icon: <FiGitPullRequest className='text-warning fs-7' />,
		color: 'warning',
		title: 'Roles',
		description: 'Provide agents with fine-grained access and privileges.',
	},
	{
		icon: <FiClock className='text-success fs-7' />,
		color: 'success',
		title: 'Working Hours',
		description: 'To set client expectations, define operating hours and holidays.',
	},
	{
		icon: <FiBriefcase className='text-primary fs-7' />,
		color: 'primary',
		title: 'Skills',
		description: 'Assign kinds of tickets to agents based on their expertise.',
	},
	{
		icon: <FiRepeat className='text-info fs-7' />,
		color: 'info',
		title: 'Agent Changes',
		description: 'Create and manage agent schedules all in one spot.',
	},
];

export const account = [
	{
		icon: <FiUserCheck className='text-primary fs-7' />,
		color: 'primary',
		title: 'Account Information',
		description: 'View the status of your account as well as your invoice email address.',
	},
	{
		icon: <FiFileText className='text-info fs-7' />,
		color: 'info',
		title: 'Billing & Plans',
		description: 'Plan, add-ons, team size, and billing cycle are all under your control.',
	},
	{
		icon: <FiSunrise className='text-warning fs-7' />,
		color: 'warning',
		title: 'Passes for the day',
		description: 'Part-time agents can purchase on-demand licenses.',
	},
	{
		icon: <FiLock className='text-success fs-7' />,
		color: 'success',
		title: 'Security',
		description: 'Advanced SSO settings, password policy, and domain restriction.',
	},
	{
		icon: <FiHexagon className='text-primary fs-7' />,
		color: 'primary',
		title: 'Log of Audits',
		description: 'All of the changes made to your falcon Support tickets account.',
	},
	{
		icon: <FiAlertCircle className='text-info fs-7' />,
		color: 'info',
		title: 'Configure the Helpdesk',
		description: 'Your Falcon Support Tickets will be personalized.',
	},
];
