import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Badge, Card, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTable from '../../../components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from '../../../components/common/advance-table/AdvanceTableFooter';
import CardDropdown from '../../../components/common/CardDropdown';
import Loader from '../../../components/Loader';
import Message from '../../../components/Message';
import useAdvanceTable from '../../../hooks/useAdvanceTable';
import AdvanceTableProvider from '../../../providers/AdvanceTableProvider';

const AcceptingOrderPendings = ({ data, isLoading, isError }) => {
	const columns = useMemo(
		() => [
			{
				accessorKey: 'orderId',
				header: '订单编号',
				meta: {
					headerProps: { className: 'sticky-column text-900 ' },
					cellProps: {
						className: 'fw-semibold',
					},
				},
				cell: ({ row: { original } }) => {
					const { orderId, _id, deliveryStatus } = original;
					let bg = '';
					let icon = '';
					let text = '';
					switch (deliveryStatus) {
						case 'waitingAccept':
							bg = 'info';
							icon = 'fa-truck-ramp-box';
							text = '待验收';
							break;
						default:
							break;
					}
					return (
						<>
							<div className='d-flex justify-content-center align-items-center'>
								<div>
									<OverlayTrigger
										overlay={
											<Tooltip style={{ position: 'fixed' }} id='basePriceTooltip'>
												<FontAwesomeIcon icon={icon} className={classNames('me-2', `text-${bg}`)} />
												{text}
											</Tooltip>
										}
									>
										<FontAwesomeIcon icon='circle' className={classNames('me-2', `text-${bg}`)} />
									</OverlayTrigger>
								</div>

								<Link to={`/order/detail/${_id}`} className='text-info'>
									{orderId}
								</Link>
							</div>
						</>
					);
				},
			},
			{
				accessorKey: 'customerName',
				header: '客户名称',
				meta: {
					headerProps: { className: 'pe-3 text-900 text-start' },
					cellProps: { className: 'text-start' },
				},
				cell: ({ row: { original } }) => {
					const { customerInfo } = original;
					return <span className='text-info'>{customerInfo.customerName}</span>;
				},
			},
			{
				accessorKey: 'approvalStatus',
				header: '物流状态',
				meta: {
					headerProps: { className: 'pe-3 text-900' },
				},
				cell: ({ row: { original } }) => {
					const { deliveryStatus } = original;
					let text = '';
					switch (deliveryStatus) {
						case 'waitingAccept':
							text = '待验收';
							break;
						default:
							break;
					}
					return (
						<Badge bg='info' className='me-2'>
							<span className='fs-11'>{text}</span>
						</Badge>
					);
				},
			},
			{
				accessorKey: 'totalPrice',
				header: '总价',
				meta: {
					headerProps: {
						className: 'text-end text-900',
					},
					cellProps: {
						className: 'text-end',
					},
				},
				cell: ({ row: { original } }) => {
					return <span>¥{original.totalPrice}</span>;
				},
			},
			{
				accessorKey: 'createdAt',
				header: '创建时间',
				meta: {
					headerProps: {
						className: 'text-end text-900',
					},
					cellProps: {
						className: 'text-end',
					},
				},
				cell: ({ row: { original } }) => {
					return <span>{moment(original.createdAt).format('YYYY-MM-DD HH:mm')}</span>;
				},
			},
			{
				accessorKey: 'none',
				header: '',
				enableSorting: false,
				meta: {
					cellProps: {
						className: 'text-end py-2',
					},
				},
				cell: ({ row: { original } }) => {
					return (
						<CardDropdown drop='start'>
							<div className='py-2'>
								<Dropdown.Item href={`/order/detail/${original?._id}`}>查看</Dropdown.Item>
							</div>
						</CardDropdown>
					);
				},
			},
		],
		[]
	);

	const table = useAdvanceTable({
		data,
		columns: columns,
		sortable: true,
		pagination: true,
		perPage: 7,
		initialState: {
			sorting: [{ id: 'createdAt', desc: true }],
		},
	});

	return (
		<AdvanceTableProvider {...table}>
			<AdvanceTable
				headerClassName='bg-200 text-nowrap align-middle font-sans-serif'
				rowClassName='btn-reveal-trigger text-nowrap align-middle'
				tableProps={{
					className: 'fs-10 fw-semibold mb-0 overflow-hidden',
				}}
			/>
			{!isLoading && data.length === 0 && (
				<div className='my-5 w-100 text-center'>
					<p className=''>暂无待验收订单</p>
				</div>
			)}
			{isLoading && <Loader />}
			{isError && <Message animation='border' variant='primary' className='mt-3' />}
			<Card.Footer>
				<AdvanceTableFooter rowInfo navButtons />
			</Card.Footer>
		</AdvanceTableProvider>
	);
};

export default AcceptingOrderPendings;
