export const version = '0.1.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const themeVariants = ['light', 'dark', 'auto'];
export const settings = {
	isFluid: true,
	isRTL: false,
	isDark: false,
	theme: 'light',
	navbarPosition: 'vertical',
	showBurgerMenu: false, // controls showing vertical nav on mobile
	currency: '¥',
	isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
	navbarStyle: 'transparent',
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
