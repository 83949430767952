import { DASHBOARD_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const dashboardApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getStatisData: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/statis/card`,
			}),
		}),
		getPendingOrderForManager: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/order/pending/manager`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
		getPendingOrderForFinance: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/order/pending/finance`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
		getPendingOrderForBuyer: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/order/pending/buyer`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
		getPendingOrderForSale: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/order/pending/sale`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
		getPendingOrderForAssistant: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/order/pending/assistant`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
		getApprovingOrders: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/order/approving`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
		getDeliveringOrders: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/order/delivering`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
		getAcceptingOrders: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/order/accepting`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
		getPrePaymentOrders: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/order/prepayment`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
		getContractOrders: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/order/contract`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
		getAccountsReceivableOrders: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/order/ar`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
		getApplingOrders: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/order/appling`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
	}),
});

export const {
	useGetStatisDataQuery,
	useGetPendingOrderForManagerQuery,
	useGetPendingOrderForFinanceQuery,
	useGetPendingOrderForBuyerQuery,
	useGetPendingOrderForSaleQuery,
	useGetPendingOrderForAssistantQuery,
	useGetApprovingOrdersQuery,
	useGetDeliveringOrdersQuery,
	useGetAcceptingOrdersQuery,
	useGetPrePaymentOrdersQuery,
	useGetContractOrdersQuery,
	useGetAccountsReceivableOrdersQuery,
	useGetApplingOrdersQuery,
} = dashboardApiSlice;
