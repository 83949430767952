import React, { useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import { DashboardQiuckButton } from '../../data/dashboard/quickLinksData';
import Flex from '../../components/common/Flex';
import { useSelector } from 'react-redux';

const DashboardBtnsItem = ({ button }) => {
	return (
		<Col lg={12} xxl={12}>
			<Flex alignItems='center' className='px-3 py-x1 bg-body-tertiary rounded-3 border position-relative'>
				<img src={button.img} alt='' width='39' />
				<div className='ms-3'>
					<h5 className='fs-9 fw-semibold mb-2'>
						<a href={button.link} className='text-900 hover-primary stretched-link'>
							{button.title}
						</a>
					</h5>
					<h6 className='mb-0 text-600'>{button.description}</h6>
				</div>
			</Flex>
		</Col>
	);
};

const QuickOperateButton = () => {
	const { userInfo } = useSelector((state) => state.auth);
	const loadBtn = useMemo(() => {
		let basicBtns = DashboardQiuckButton.basicBtn;
		let newBtns = [];
		switch (userInfo.role) {
			case 'admin':
				newBtns = DashboardQiuckButton.adminBtn;
				break;
			case 'manager':
				newBtns = DashboardQiuckButton.managerBtn.concat(basicBtns);
				break;
			case 'finance':
				newBtns = DashboardQiuckButton.financeBtn.concat(basicBtns);
				break;
			case 'buyer':
				newBtns = DashboardQiuckButton.buyerBtn.concat(basicBtns);
				break;
			case 'user':
				newBtns = DashboardQiuckButton.userBtn.concat(basicBtns);
				break;
			case 'sale':
				newBtns = DashboardQiuckButton.saleBtn.concat(basicBtns);
				break;
			case 'assistant':
				newBtns = DashboardQiuckButton.assistantBtn.concat(basicBtns);
				break;
			default:
				return basicBtns;
		}
		return newBtns;
	}, [userInfo.role]);

	return (
		<Card bg='primary' className='dark__bg-dark border dark_border-warning'>
			<Card.Body>
				<h5 className='fs-9 mb-3 text-white dark__text-white'>从这里开始您的工作</h5>
				<Row className='g-3'>
					{loadBtn.map((btn, index) => (
						<DashboardBtnsItem key={index} button={btn} />
					))}
				</Row>
			</Card.Body>
		</Card>
	);
};

export default QuickOperateButton;
